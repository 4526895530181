import {client as clientAxios} from "../../../../library/axios.library";
import {AxiosResponse} from "axios";
import {UserFormType, UserType} from "@user/types/user.types"

export const getUser = async (id: string): Promise<UserType> => {
    const {data} = await clientAxios.get<null, AxiosResponse<UserType>>(`/api/v1/user/${id}`);
    return data;
}

export const saveUser = async (data: UserFormType, id?: string) => {
    if (id) {
        return updateUser(id, data);
    }

    return createUser(data);
}

export const createUser = async (data: UserFormType) => {
    return await clientAxios.post<UserFormType, AxiosResponse>('/api/v1/user/create', data);
}

export const updateUser = async (id: string, data: UserFormType) => {
    return await clientAxios.patch<UserFormType, AxiosResponse>(`/api/v1/user/${id}/update`, data);
}
