import AppLayout from "../../components/AppLayout";
import {RouterProvider, createBrowserRouter} from "react-router-dom";

import App404 from "../../components/App404";
import {OfferView, OfferCreateView, OfferListView, OfferEditView} from "../../modules/offer/views";
import {ClientListView} from "../../modules/client/views";
import {ClientView} from "../../modules/client/views";
import {ClientCreateView} from "../../modules/client/views";
import React from "react";
import {ToastContainer} from "react-toastify";
import ClientEditView from "../../modules/client/views/ClientEdit/ClientEdit.view";
import {UserEdit, UserListView, UserView, UserCreate} from "../../modules/user/views";
import {ProcessCreateView, ProcessListView, ProcessEditView, ProcessCardView} from "../../modules/process/views";
import {EstimateCardView, EstimateListView} from "@estimate/views";

const router = createBrowserRouter([
    {path: '/', element: <AppLayout/>, children: [
        {path: '/', element: <OfferListView/>},
        {path: '/offers', element: <OfferListView/>},
        {path: '/offers/:id', element: <OfferView/>},
        {path: '/offers/create', element: <OfferCreateView/>},
        {path: '/offers/edit/:id', element: <OfferEditView/>},
        {path: '/clients', element: <ClientListView/>},
        {path: '/clients/:id', element: <ClientView/>},
        {path: '/clients/create', element: <ClientCreateView/>},
        {path: '/clients/edit/:id', element: <ClientEditView/>},
        {path: '/users', element: <UserListView/>},
        {path: '/user/:id', element: <UserView/>},
        {path: '/user/create', element: <UserCreate/>},
        {path: '/user/edit/:id', element: <UserEdit/>},
        {path: '/process', element: <ProcessListView/>},
        {path: '/process/:id', element: <ProcessCardView/>},
        {path: '/process/create', element: <ProcessCreateView/>},
        {path: '/process/edit/:id', element: <ProcessEditView/>},
        {path: '/estimate', element: <EstimateListView/>},
        {path: '/estimate/:id', element: <EstimateCardView/>},
        {path: '*', element: <App404/>}
    ]},
]);

function App() {

    return (
        <div className="App">
            <ToastContainer/>
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
